import * as React from 'react';
import { Avatar, Button, Card, Dialog, Divider, IconButton, Modal, Portal, Text } from 'react-native-paper';
import { View, StyleSheet } from 'react-native';
import { connect } from 'react-redux';
import globalStyles from './Styles';
import moment from 'moment';
import { TouchableOpacity } from 'react-native';

export const AlertDialog = ({ visible, handleClose, handleAccept, handleAction, message, okText, actionText, token, user }) => {
    const role = token?.split(" ")[0]

    return (
        <View>
            <Portal>
                <Dialog visible={visible} onDismiss={handleClose}>
                    <Dialog.Title>Alert</Dialog.Title>
                    <Dialog.Content>
                        <Text variant="bodyMedium">{message}</Text>
                    </Dialog.Content>
                    <Dialog.Actions>
                        <Button onPress={handleAccept}>{okText}</Button>
                        {handleAction && <Button onPress={handleAction}>{actionText}</Button>}
                        <Button onPress={handleClose}>CANCEL</Button>
                    </Dialog.Actions>
                </Dialog>
            </Portal>
        </View>
    )
}

const styles = StyleSheet.create({
    containerStyle: {
        backgroundColor: 'white',
        padding: 30,
        justifyContent: "center",
        alignItems: "center",
        margin: 30,
        borderRadius: 10
    },
    modalContent: {
        backgroundColor: 'white',
        padding: 20,
        borderRadius: 10,
        alignItems: 'center',
    },
    messageText: {
        fontSize: 18,
        marginBottom: 20,
    },
    button: {
        fontSize: 18,
        margin: 10,
    },
});

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(AlertDialog);