import { WAIT, WAIT_SUCCESS, WAIT_FAIL, FETCH_RECORDS, FETCH_RECORDS_SUCCESS, FETCH_RECORDS_FAIL, ECXEPTION } from "./types";
import { delRequest, getRequest, postRequest, putRequest } from "../../../core/network";
import endpoints from "../../../constants/endPoints.json";
import * as FileSystem from 'expo-file-system';
import * as Sharing from 'expo-sharing';
import { Buffer } from "buffer";
import axios from "axios";
import download from "downloadjs";
import { Platform } from "react-native";

export const sendNotification = (data) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState().auth?.token

    postRequest(data, undefined, undefined, token, endpoints.super.notifications)
        .then(async (response) => {
            alert(response.data?.message)
            return dispatch({
                type: WAIT_SUCCESS,
            });
        })
        .catch((err) => {
            console.log(err);
            alert(err.response.data.message)
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const takeAttendance = (data) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState().auth?.token

    putRequest(data, undefined, undefined, token, endpoints.super.academy.attendance[data.segment])
        .then(async (response) => {
            alert(response.data.message)
            return dispatch({
                type: WAIT_SUCCESS,
            });
        })
        .catch((err) => {
            console.log(err);
            alert(err.response.data.message)
            dispatch({
                type: ECXEPTION,
                payload: data
            });
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const takeExceptionalAttendance = (data) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState().auth?.token

    putRequest(data, undefined, undefined, token, endpoints.super.academy.attendance.traineeException)
        .then(async (response) => {
            alert(response.data.message)
            return dispatch({
                type: WAIT_SUCCESS,
            });
        })
        .catch((err) => {
            console.log(err);
            alert(err.response.data.message)
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const superPayments = (data) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState().auth?.token

    getRequest(data, undefined, token, endpoints.super.academy.payments.get)
        .then(async (response) => {
            const { data } = response
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            console.log(err);
            alert(err.response.data.message)
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const acceptPayment = (data) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState().auth?.token

    putRequest(data, undefined, undefined, token, endpoints.super.academy.payments.accept)
        .then(async (response) => {
            const { data } = response
            alert(data.message)
            return dispatch({
                type: WAIT_SUCCESS,
            });
        })
        .catch((err) => {
            console.log(err);
            alert(err.response.data.message)
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const rejectPayment = (data) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState().auth?.token

    delRequest(data, undefined, token, endpoints.super.academy.payments.delete)
        .then(async (response) => {
            const { data } = response
            alert(data.message)
            return dispatch({
                type: WAIT_SUCCESS,
            });
        })
        .catch((err) => {
            console.log(err);
            alert(err.response.data.message)
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const getPayouts = (data) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState().auth?.token

    getRequest(data, undefined, token, endpoints.super.academy.payments.payouts)
        .then(async (response) => {
            const { data } = response
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            console.log(err);
            alert(err.response.data.message)
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const setPayouts = (data) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState().auth?.token

    putRequest(data, undefined, undefined, token, endpoints.super.academy.payments.payouts)
        .then(async (response) => {
            const { data } = response
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            console.log(err);
            alert(err.response.data.message)
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const createAcademy = (data, navigation) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState().auth?.token

    postRequest(data, undefined, undefined, token, endpoints.super.academy.root)
        .then(async (response) => {
            navigation && navigation.navigate("AcademyHome")
            return dispatch({
                type: WAIT_SUCCESS,
            });
        })
        .catch((err) => {
            console.log(err);
            alert(err.response.data.message)
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const editAcademy = (data, navigation) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState().auth?.token

    putRequest(data, undefined, undefined, token, endpoints.super.academy.root)
        .then(async (response) => {
            navigation && navigation.goBack()
            return dispatch({
                type: WAIT_SUCCESS,
            });
        })
        .catch((err) => {
            console.log(err);
            alert(err.response.data.message)
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const deleteAcademy = (data, navigation) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState().auth?.token

    delRequest(data, undefined, token, endpoints.super.academy.root)
        .then(async (response) => {
            navigation && navigation.goBack()
            return dispatch({
                type: WAIT_SUCCESS,
            });
        })
        .catch((err) => {
            console.log(err);
            alert(err.response.data.message)
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const sendPaymentReminder = () => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState().auth?.token

    getRequest(undefined, undefined, token, endpoints.super.academy.payments.reminder)
        .then(async (response) => {
            alert(response.data?.message)
            return dispatch({
                type: WAIT_SUCCESS,
            });
        })
        .catch((err) => {
            console.log(err);
            alert(err.response.data.message)
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const getPaymentsStatement = (data) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState().auth?.token

    getRequest({ ...data, responseType: "arraybuffer" }, undefined, token, endpoints.super.academy.payments.statement)
        .then(async (response) => {

            const arrayBuffer = response.request._response;
            const fileName = 'ROAR_Payments.xlsx';

            if(Platform.OS === "web") {
                download(arrayBuffer)
            }

            const directory = `${FileSystem.cacheDirectory}payments/`;
            await FileSystem.makeDirectoryAsync(directory, { intermediates: true });
            const filePath = `${directory}${fileName}`;

            // Write the blob content to the file
            await FileSystem.writeAsStringAsync(filePath, arrayBuffer, {
                encoding: FileSystem.EncodingType.Base64,
            })

            // Open the sharing dialog to allow the user to download and save the file
            await Sharing.shareAsync(filePath, {
                mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                dialogTitle: 'Download Payments Excel',
                UTI: 'public.xlsx',
            });

            return dispatch({
                type: WAIT_SUCCESS,
            });
        })
        .catch((err) => {
            console.log(err);
            alert(err.response.data.message)
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const createAdmin = (data, navigation) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState().auth?.token

    postRequest(data, undefined, undefined, token, endpoints.super.admin)
        .then(async (response) => {
            navigation && navigation.goBack()
            return dispatch({
                type: WAIT_SUCCESS,
            });
        })
        .catch((err) => {
            console.log(err);
            alert(err.response.data.message)
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const createCoach = (data, navigation) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState().auth?.token

    postRequest(data, undefined, undefined, token, endpoints.super.academy.coach)
        .then(async (response) => {
            navigation && navigation.navigate("AcademyHome")
            return dispatch({
                type: WAIT_SUCCESS,
            });
        })
        .catch((err) => {
            console.log(err);
            alert(err.response.data.message)
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const editCoach = (data, navigation) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState().auth?.token

    putRequest(data, undefined, undefined, token, endpoints.super.academy.coach)
        .then(async (response) => {
            navigation && navigation.goBack()
            return dispatch({
                type: WAIT_SUCCESS,
            });
        })
        .catch((err) => {
            console.log(err);
            alert(err.response.data.message)
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const deleteCoach = (data, navigation) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState().auth?.token

    delRequest(data, undefined, token, endpoints.super.academy.coach)
        .then(async (response) => {
            navigation && navigation.goBack()
            return dispatch({
                type: WAIT_SUCCESS,
            });
        })
        .catch((err) => {
            console.log(err);
            alert(err.response.data.message)
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const addSessions = (data, navigation) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState().auth?.token

    postRequest(data, undefined, undefined, token, endpoints.super.academy[data.variant])
        .then(async (response) => {
            const { data } = response
            alert(data.message)
            navigation && navigation.goBack()
            return dispatch({
                type: WAIT_SUCCESS,
            });
        })
        .catch((err) => {
            console.log(err);
            alert(err.response.data.message)
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const editSession = (data, navigation) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState().auth?.token

    putRequest(data, undefined, undefined, token, endpoints.super.academy.slot)
        .then(async (response) => {
            const { data } = response
            navigation && navigation.goBack()
            return dispatch({
                type: WAIT_SUCCESS,
            });
        })
        .catch((err) => {
            console.log(err);
            alert(err.response.data.message)
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};